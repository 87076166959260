import React, { useEffect } from "react";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import P1 from "../../assets/pages/1.png";

export default function AWS() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img4 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Amazon Web Services (AWS)" />

              <span className="sub-text">
                If you possess prior knowledge of Amazon Web Services, that's
                great. However, if you're still seeking answers, then you have
                arrived at the correct page. We are here to provide you with
                comprehensive guidance and the most recent information on AWS.
                With our extensive experience in AWS, we can assist you with the
                latest and most sophisticated AWS services and guidance.{" "}
              </span>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}
      {/* About Section Start */}
      <Section>
        <div className="rs-about py-0">
          <div className="container custom">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 md-mb-50">
                <div className="images-part">
                  <img src={P1} alt="Images" className="w-100" />
                </div>
              </div>
              <div className="col-lg-6 pl-60 md-pl-15">
                <div className="sec-title">
                  <h1>AWS</h1>
                  <p className="margin-0 pt-15">
                    Purchasing cloud storage space and retaining data can be
                    considerably costly. However, the importance of procuring
                    cloud space for enterprises cannot be underestimated. That's
                    where Deltalake systems can assist your business.
                  </p>
                  <p className="margin-0 pt-15">
                    Leveraging our unparalleled expertise and familiarity with
                    AWS, we can provide assistance in obtaining cost-effective
                    benefits. Our aim is to deliver superior service with AWS
                    while also guiding you every step of the way.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* About Section End */}

      <Section className="bg-gray">
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22">
                    Select Deltalake Systems for superior assistance with AWS.
                    Get in touch with us now and bid farewell to your concerns!
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* Services Section End */}
      <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Here’s how we can help" />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    {/* <div class="services-icon">
                                <img src="assets/images/services/style3/4.png" alt="Services">
                            </div> */}
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Migration <br />
                          Guide
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        As technology continues to evolve, businesses are
                        transitioning from conventional practices to modern
                        ones. We facilitate your migration from Hadoop to AWS by
                        providing a suitable and analytical migration guide.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Trusted Data <br /> Platform
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We can assist you in creating a superior online data
                        platform. Our free cloud automation tool enables you to
                        construct infrastructure and rectify errors on the go.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Data Pipelines with <br /> AWS Cloud Formation
                        </a>
                      </h5>
                      <p className="services-txt">
                        Managing data pipelines can be challenging, especially
                        when dealing with large volumes of data. To simplify
                        matters, the AWS CloudFormation tool creates your cloud
                        services in a more flexible manner.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    {/* <div class="services-icon">
                                <img src="assets/images/services/style3/1.png" alt="Services">
                            </div> */}
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Low-Cost AWS <br /> Cloud DataOps
                        </a>
                      </h5>
                      <p className="services-txt">
                        Leveraging the data storage services on the AWS cloud
                        can be costly. Our company can assist you in reducing
                        platform administration expenses.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Data Transmission over Amazon Cloud Formation" />
              <p className="mt-5">
                AWS Data Pipeline is an online service designed to facilitate
                the secure and consistent transfer and processing of data across
                various AWS storage and compute services, as well as on-premises
                data sources, at pre-defined intervals. The service allows for
                seamless data movement and processing that was previously
                confined to on-premises data silos.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Platform for Trusted Data" />
              <p className="mt-5">
                AWS Data Pipeline is a powerful ETL (Extract, Transform, and
                Load) service that enables you to automate data movement and
                transformation. You can easily create your workflow using the
                AWS Management Console or automate the process using the AWS
                Command Line Interface or API to manage and create pipelines.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

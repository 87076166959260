import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import T9 from "../../assets/images/t9.png";

export default function MachineLearning() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img5 machine-learning-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Machine Learning frameworks" />
              <p className="sub-text">
                Our dedicated group of proficient experts are committed to
                delivering effective IT solutions. Our team of machine learning
                engineers are always ready to support you. These engineers are
                skilled, extensively trained, and possess vast experience in the
                utilization of large amounts of data.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-5 md-mb-50">
                <div className="images-part">
                  <img
                    src={T9}
                    alt="Images"
                    className="w-100"
                    style={{ opacity: 0.8 }}
                  />
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-6 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText=" Machine Learning" />
                  <p className="margin-0 pt-15">
                    Attaining customer satisfaction is crucial for a company to
                    succeed in the market. This can be achieved by recognizing
                    the preferences and behavior of customers. Presently, the
                    employment of Machine Learning and Artificial Intelligence
                    technologies is essential in achieving this objective.
                  </p>
                  <p className="margin-0 pt-15">
                    Enterprises can obtain information about customer behavior
                    trends and gain insights into the preferences and
                    expectations of both current and potential customers by
                    leveraging these technologies.
                  </p>
                  <p className="margin-0 mt-3">
                    Cutting-edge applications and tools are essential in
                    effectively implementing the latest algorithms to perform
                    diverse operations. However, achieving this goal is not
                    possible without the expertise of skilled Machine Learning
                    engineers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="How can Deltalake Systems assist you?" />

                  <p className="margin-0 pt-15">
                    Our team comprises of highly skilled professionals who are
                    dedicated to delivering efficient IT solutions. With our
                    team of Machine Learning engineers, you can always count on
                    us for support. Our engineers possess strong qualifications,
                    extensive training, and ample experience in utilizing large
                    amounts of data.{" "}
                  </p>
                  <ul className="margin-0 mt-4">
                    <h5>This is what makes them different:</h5>
                    <li className="margin-0 mt-3">
                      The Machine Learning engineers gather relevant data and
                      leverage ML tools to construct models that deliver
                      superior outcomes. They prioritize the present value while
                      also taking into account the future prospects of Machine
                      Learning.
                    </li>
                    <li className="margin-0 mt-3">
                      Images, audio, and video data are typically massive in
                      size. Our ML engineers utilize advanced algorithms and
                      data pipelines to develop error-free and responsive
                      programs that can effectively process complex data.
                    </li>
                    <li className="margin-0 mt-3">
                      Our ML engineers develop MLOps platforms that are highly
                      responsive and capable of performing various Machine
                      Learning data operations, including the creation of
                      serverless deployment models.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="The following are the responsibilities of skilled Machine Learning engineers." />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Generating Data for
                          <br /> Business Growth
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Skilled Machine Learning engineers are responsible for
                        generating data that can facilitate business growth.
                        They utilize data-driven decision-making technology to
                        identify trends, patterns, and insights that can inform
                        strategic decisions. By analyzing large amounts of data,
                        ML engineers can uncover valuable insights that can be
                        used to optimize business operations, increase revenue,
                        and improve customer satisfaction.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Implementing Data Models <br />
                          for Accurate Results
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        ML engineers play a crucial role in ensuring the
                        accuracy of data-driven decision making. They implement
                        data models that are designed to effectively process and
                        analyze data, ensuring that the results are both
                        reliable and accurate. With their expertise in data
                        modeling, ML engineers can identify and correct errors
                        in the data, ensuring that it is of the highest quality.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Creating MLOps Platforms for <br />
                          Efficient Data Operations
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Machine Learning Operations (MLOps) platforms are
                        essential for performing various Machine Learning data
                        operations. Skilled ML engineers can create MLOps
                        platforms that are highly responsive and capable of
                        performing complex data operations efficiently. With
                        their expertise in data management and software
                        engineering, ML engineers can design MLOps platforms
                        that are optimized for speed, accuracy, and efficiency.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Prioritizing Present Value and Future Prospects
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Skilled ML engineers prioritize the present value while
                        also taking into account the future prospects of Machine
                        Learning. They identify and leverage emerging
                        technologies, ensuring that businesses stay ahead of the
                        curve. By prioritizing present value and future
                        prospects, ML engineers can help organizations achieve
                        long-term success.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 sm-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Designing Serverless <br /> Deployment Models
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        ML engineers are responsible for designing serverless
                        deployment models for MLOps platforms. By leveraging
                        serverless deployment models, they can create scalable
                        and cost-effective solutions that can handle large
                        volumes of data. This enables businesses to effectively
                        manage and analyze data, without having to invest in
                        costly infrastructure.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

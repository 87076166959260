import React from "react";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

export default function CaseStudies() {
  return (
    <div>
      <>
        <div className="container">
          <Section>
            <Primaryheading primaryText="Case Studies" />
            <div className="row">
              <div className={"col-12 col-sm-6"}>
                <GridBox>
                  <h5>A case study in Data Engineering</h5>
                  <p>
                    Our team of data engineering experts assisted a major
                    e-commerce company in enhancing their data infrastructure
                    and processing capabilities. We designed and implemented a
                    strong data pipeline that efficiently processed millions of
                    daily transactions. Additionally, we optimized their data
                    storage and retrieval processes, enabling the company to
                    extract valuable insights from their data and make informed
                    decisions. Consequently, the company experienced a
                    remarkable enhancement in operational efficiency, resulting
                    in increased revenue.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-6"}>
                <GridBox>
                  <h5>A case study in E-commerce</h5>
                  <p>
                    We collaborated with a startup in the e-commerce industry to
                    create a tailor-made e-commerce platform that met their
                    unique requirements. Our team of software developers
                    designed and constructed a scalable platform capable of
                    processing high volumes of traffic and transactions.
                    Additionally, we incorporated several third-party services
                    such as payment gateways and shipping providers to ensure a
                    seamless customer experience. This platform facilitated the
                    establishment of a robust online presence for the company
                    and boosted their sales.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-6"}>
                <GridBox>
                  <h5>Technology Migration Case Study</h5>
                  <p>
                    We supported a significant financial services company in
                    transferring their legacy systems to a modern cloud-based
                    platform. Our team of professionals devised and implemented
                    a smooth migration plan, which reduced downtime and
                    guaranteed data accuracy. Additionally, we provided the
                    company's staff with training and assistance to ensure a
                    seamless transition. As a result, the company was able to
                    optimize their operations and reduce expenses.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-6"}>
                <GridBox>
                  <h5>Management Consulting Case Study</h5>
                  <p>
                    We delivered management consulting services to a mid-sized
                    manufacturing company that faced profitability issues. Our
                    team of consultants conducted an analysis of the company's
                    processes and identified areas for improvement. We
                    formulated a comprehensive strategy that centred around
                    streamlining their supply chain, minimising waste, and
                    enhancing their production processes. This strategy led to a
                    boost in the company's profitability and enabled them to
                    stay ahead of the competition in the market.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-6"}>
                <GridBox>
                  <h5>Data Validation Case Study</h5>
                  <p>
                    We collaborated with a healthcare company to verify the
                    accuracy of their data and ensure compliance with regulatory
                    standards. Our team of data validation specialists created
                    and implemented a validation plan that involved conducting
                    data profiling, quality checks, and completeness checks.
                    Additionally, we provided suggestions on how to enhance
                    their data management procedures. Consequently, the company
                    enhanced their data quality and met the regulatory
                    requirements.
                  </p>
                </GridBox>
              </div>
            </div>
          </Section>
        </div>
      </>
    </div>
  );
}

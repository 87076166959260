import React from "react";
import { BgHeading, Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import BG from "../../assets/images/8.svg";
import Img9 from "../../assets/images/9.svg";

export default function AboutUs() {
  return (
    <div>
      <BgHeading>
        <img src={BG} alt="" />
        <h5>About Deltalake SystemsInc.</h5>
        <div className="container">
          <Section className="about-main-content">
            <p>
              Deltalake Systems is a leading IT solutions company with over 5
              years of experience specializing in AI and ML services. Our
              unwavering commitment to delivering top-tier solutions and
              providing assistance to our clients has earned us the trust of
              leading companies across various industries. Our team of experts
              is dedicated to staying up-to-date with the latest advancements in
              AI and ML technology, using cutting-edge techniques to provide
              innovative solutions that cater to our clients' unique
              requirements. With a focus on customer satisfaction, we strive to
              deliver excellence in all that we do, empowering our clients to
              achieve their business objectives. Regardless of whether you
              require AI and ML solutions or other IT services, we are always
              available to assist you in every step of the way, staying ahead of
              the curve in the ever-evolving world of technology.
            </p>
            <div className="row pt-5">
              <div className="col-12 col-sm-7">
                <Primaryheading primaryText="We help to implement your ideas into automation" />
                <p>
                  To keep up with the rapidly changing world of technology, it
                  is essential to incorporate AI and machine learning tools into
                  your business operations. These data-driven techniques are the
                  future of business optimization, and it is crucial to embrace
                  this change and make the most of it. By doing so, you can
                  enhance your decision-making processes and expand your
                  customer base.
                </p>
                <p>
                  At Deltalake Systems, we understand the importance of quality
                  over quantity, and this is what sets us apart from other IT
                  solutions providers in the market. We are committed to
                  delivering exceptional services to our clients and assisting
                  them in every way possible. As a result, we have earned the
                  trust of leading companies across various industries.
                </p>
                <p>
                  We believe in staying up-to-date with the latest advancements
                  in technology, which is why we upgrade our tools and
                  techniques as per the changing landscape. This enables us to
                  cater to our clients' evolving needs and provide them with
                  innovative solutions that drive outstanding business growth.
                  Our team of data engineers, architects, and strategists works
                  collaboratively to help you efficiently manage and utilize
                  data for optimal results.
                </p>
                <p>
                  Whether you need assistance with IoT data employment, data
                  engineers, or AI strategists, Deltalake Systems can arrange a
                  team that meets your specific requirements. We take pride in
                  working with our clients to build effective cloud platforms
                  from scratch, ensuring 100% positive results every step of the
                  way.{" "}
                </p>
                <p>
                  Our team is always ready to take on new challenges and provide
                  top-tier solutions that exceed our clients' expectations. With
                  Deltalake Systems as your IT solutions partner, you can rest
                  assured that your business operations are in capable hands.
                </p>
              </div>
              <div className="col-12 col-sm-5">
                <img src={Img9} alt="" />
              </div>
            </div>
          </Section>
          <Section>
            <div className="row">
              <Primaryheading primaryText="Why Choose Us" />
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2>Experience and Expertise</h2>
                  <p>
                    Deltalake systems have a team of experts with extensive
                    experience in various industries. Our consultants are highly
                    skilled in their respective fields and have a deep
                    understanding of the latest trends and technologies.
                  </p>
                </GridBox>
              </div>
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2>Innovative Approach</h2>
                  <p>
                    At our consulting company, we believe in staying ahead of
                    the curve when it comes to technology and innovation. We
                    leverage the latest tools and techniques to provide our
                    clients with innovative solutions that help them gain a
                    competitive edge in the market.
                  </p>
                </GridBox>
              </div>
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2>Customer Satisfaction</h2>
                  <p>
                    At the core of our consulting company is a focus on customer
                    satisfaction. We are committed to providing our clients with
                    high-quality service and ensuring that they are fully
                    satisfied with our solutions. We take pride in building
                    long-term relationships with our clients and being a trusted
                    partner in their success.
                  </p>
                </GridBox>
              </div>
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2> Results-Oriented</h2>
                  <p>
                    We are a results-oriented consulting company and strive to
                    deliver measurable outcomes for our clients. We work with
                    our clients to define clear goals and objectives, and we
                    measure our success based on our ability to achieve these
                    goals. Our consultants are highly analytical and
                    data-driven, ensuring that we provide solutions that deliver
                    tangible results.
                  </p>
                </GridBox>
              </div>
              <div className={"col-6 col-sm-4"}>
                <GridBox>
                  <h2>Flexibility and Adaptability</h2>
                  <p>
                    We understand that businesses operate in a dynamic
                    environment, and requirements can change rapidly. That's why
                    we pride ourselves on being flexible and adaptable to our
                    clients' changing needs. We work closely with our clients to
                    ensure that we can quickly pivot and adjust our solutions to
                    meet their evolving requirements.
                  </p>
                </GridBox>
              </div>
            </div>
          </Section>
        </div>
      </BgHeading>
    </div>
  );
}

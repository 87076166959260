import React from "react";
import { Banner } from "./styles";
import loadable from "@loadable/component";
import BannerImgGif from "../../assets/images/banner-video.gif";

export default function HeaderBanner() {
  return (
    <Banner>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={BannerImgGif} className="w-100 banner-img" />
      </div>
    </Banner>
  );
}

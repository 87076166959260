import React, { FunctionComponent } from "react";
import { FooterSection, CopyrightBar } from "./styles";
import FB from "../../../assets/images/fb.svg";
import Instagram from "../../../assets/images/instagram.svg";

import Map from "../../../assets/images/map.svg";
import Mail from "../../../assets/images/mail.svg";
import { Link } from "react-router-dom";
import logoImg from "../../../assets/images/logo.svg";

const Footer: FunctionComponent = () => {
  return (
    <>
      <FooterSection>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-3">
              <img src={logoImg} alt="logo" className={"logo"} />
              {/* <h5>deltalake Inc</h5> */}
              <p className="pt-3">
                Deltalake Systems is a prominent IT solutions company
                specializing in providing AI and ML services. With five years of
                experience, we strive to offer premium solutions to our clients.
              </p>
            </div>
            <div className="col-12 col-sm-3 ps-sm-5">
              <h5>Technologies</h5>
              <ul>
                <Link to="BigData">
                  <li>Bigdata/Hadoop</li>
                </Link>
                <Link to="/DataScience">
                  <li>Data Sceience</li>
                </Link>
                <Link to="/MachineLearning">
                  <li>Machine Learning</li>
                </Link>
                <Link to="/AIStrategy">
                  <li>AI Strategy</li>
                </Link>
              </ul>
            </div>
            <div className="col-12 col-sm-3">
              <h5>Cloud</h5>
              <ul>
                <Link to="AWS">
                  <li>AWS</li>
                </Link>
                <Link to="/MicrosoftAzure">
                  <li>Microsoft Azure</li>
                </Link>
                <Link to="/Snowflake">
                  <li>Snowflake</li>
                </Link>
                <Link to="/Dataiku">
                  <li>Dataiku</li>
                </Link>
              </ul>
            </div>
            <div className="col-12 col-sm-3">
              <h5>Contact</h5>
              <ul>
                <li>
                  <img src={Map} className="pe-3" />
                  Texas 75023 USA.{" "}
                </li>
                <li>
                  {" "}
                  <img src={Mail} className="pe-3" />
                  info@deltalake.com
                </li>
                {/* <li>
                  {" "}
                  <img src={Phone} className="pe-3" />
                  (+1) 603 943 4063
                </li> */}
              </ul>
            </div>
            {/* <div className="col-12 col-sm-2">
              <h5>Follow us</h5>
              <ul>
                <li>
                  <img src={FB} />
                </li>
                <li>
                  <img src={Instagram} />
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </FooterSection>

      <CopyrightBar>
        <p>
          Deltalake Systems Copyright © 2023. All rights reserved. Developed by
          NEWDEVEX Solutions Pvt, Ltd,
        </p>
      </CopyrightBar>
    </>
  );
};

export default Footer;

import React from "react";
import GSS from "../../assets/images/careers-logo.png";
import { CareersContainerStyles } from "./styles";
import { Link } from "react-router-dom";

export default function Careers() {
  const careersData = [
    {
      id: 1,
      title: "Data Engineer",
      description:
        "For our team, we're looking for a professional Data Engineer. Data engineers are essential to creating high-quality software solutions that satisfy user needs and technological requirements. To design, create, and test software applications over the course of the whole software development lifecycle, work with cross-functional teams. Your knowledge of how to write effective, streamlined, and maintainable code utilising industry best practises will be priceless. Debug and fix software bugs, give helpful criticism, and keep up of market trends and new developments in technology. Become a member of our team and help us consistently enhance our software development procedures to produce excellent outcomes..",
      posted_date: "03-07-2023",
      location: "Texas",
      work_type: "Remote",
    },
    {
      id: 2,
      title: "SAP Consultant",
      description:
        "As a SAP Consultant, you will contribute to the success of our company's SAP projects. As a SAP Consultant, you will collaborate directly with clients to understand their business requirements and create unique solutions based on your SAP module experience. Work with cross-functional teams to analyse business processes and configure SAP systems to fit the demands of clients. Use your strong problem-solving abilities and extensive understanding of SAP features to provide high-quality outputs. Take advantage of this opportunity to advance your career and make a meaningful contribution to the field of SAP consulting.",
      posted_date: "03-07-2023",
      location: "Texas",
      work_type: "Remote",
    },
    {
      id: 2,
      title: "RPA Developer",
      description:
        "Join our team as an RPA Developer and be at the forefront of automation technologies. As an RPA Developer, you will be responsible for designing, developing, and deploying robotic process automation solutions for our clients. Collaborate with cross-functional teams to understand business processes, discover automation opportunities, and create effective RPA workflows. Use your knowledge of RPA tools and technology to design bots that streamline processes and increase productivity. Troubleshoot and debug automation difficulties, provide technical guidance, and remain up to date on RPA improvements. Join us in transforming organisations via the power of automation and driving innovation in the field of RPA..",
      posted_date: "03-07-2023",
      location: "Texas",
      work_type: "Remote",
    },
  ];
  return (
    <div>
      <div className="container">
        <CareersContainerStyles>
          <div className="item--sub-title">Careers </div>
          <h3>
            <b>
              Deltalake Systems offers the best internships and jobs, from
              startups to Fortune 500 corporations.
            </b>
          </h3>
          {careersData.map((item: any) => {
            return (
              <div className="grid">
                <div className="d-flex">
                  <img src={GSS} alt="" />
                  <div className="d-flex flex-column">
                    <h5>{item.title}</h5>
                    <p>
                      <i className="fa fa-paperclip"></i>Posted date :{" "}
                      {item.posted_date}
                    </p>

                    <p>
                      <i className="fa fa-map"></i>
                      {item.location}
                    </p>
                    <p>
                      <i className="fa fa-life-ring"></i>
                      {item.work_type}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="description">{item.description}</p>
                </div>
                <div>
                  <Link to="/ReachUs">
                    <button className="">Apply now</button>
                  </Link>
                </div>
              </div>
            );
          })}
        </CareersContainerStyles>
      </div>

      {/* <BgHeading>
        <Section className="bg-gray py-0">
          <div className="container">
            <Section className="about-main-content">
              <Primaryheading primaryText="Careers" />
              <div className="d-flex justify-content-center flex-column align-items-center">
                <p className="text-center">
                  Starlight introduces its own proprietary system flow to offer
                  branded products <br />
                  with the maximum return on investment in the digital and
                  advertising sphere.
                  <br />
                  super world.
                </p>
                <Link to="/Contact">
                  <Button className="mt-3">Join With Us</Button>
                </Link>
              </div>
            </Section>
            <WhyWorkWithUs className="d-flex justify-content-center flex-column align-items-center mt-0 pb-3">
              <h3 className="pb-5">Why Choose Deltalake Systems?</h3>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <GridBox>
                    <h4>Mission Statement</h4>
                    <p>
                      Our goal is to enable our clients with high-quality IT
                      consulting services that assist them in adapting to the
                      swiftly changing digital environment. By comprehending
                      their distinct requirements and delivering tailored
                      solutions, we aim to establish enduring relationships with
                      our clients. Our team of devoted experts is dedicated to
                      keeping abreast of technological progress and utilizing
                      them to support our clients in achieving their objectives.
                    </p>
                  </GridBox>
                </div>
                <div className="col-12 col-sm-6">
                  <GridBox>
                    <h4>Vision Statement</h4>
                    <p>
                      Our vision is to be the premier provider of IT consulting
                      services in the industry. We aspire to accomplish this by
                      providing inventive solutions that empower businesses to
                      attain their objectives and outpace their rivals.
                    </p>
                  </GridBox>
                </div>
              </div>
            </WhyWorkWithUs>
          </div>
        </Section>

        <Section className="">
          <div className="container">
            <SectionHiring>
              <p className="badge">Vacant positions</p>
              <Primaryheading primaryText="We're Hiring" />
              <p className="text-center">
                Welcome to the job vacancies page for our IT consulting firm! We
                are a rapidly expanding business that is constantly looking for
                smart and driven people to join our team. The positions we
                currently have open are listed below.
              </p>
              <div className="section-positions-list mt-5">
                {positionsData.map((item: any) => {
                  return (
                    <SectionEachPosition className="section-each-position">
                      <h2>{item.designation}</h2>
                      <div className="row d-flex justify-content-between align-items-center">
                        <p className="col-12 col-sm-9 m-0">
                          {item.description}
                        </p>
                        <div className="col-12 col-sm-3 d-flex justify-content-end">
                          <Link to="/Contact">
                            <Button>APPLY NOW</Button>
                          </Link>
                        </div>
                      </div>
                    </SectionEachPosition>
                  );
                })}
              </div>
            </SectionHiring>
          </div>
        </Section>
      </BgHeading> */}
    </div>
  );
}

import React, { FunctionComponent, useState } from "react";
import { GridBox } from "./styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import S1 from "../../../assets/images/s1.svg";
import S2 from "../../../assets/images/s2.svg";
import S3 from "../../../assets/images/s3.svg";
import S4 from "../../../assets/images/s4.svg";
import S5 from "../../../assets/images/s5.svg";
import S6 from "../../../assets/images/s6.svg";

const FourGrid: FunctionComponent = (props: any) => {
  const services = [
    {
      title: "Data Engineering services",
      src: S5,
    },
    {
      title: "Cloud Strategy Development",
      src: S2,
    },
    {
      title: "App development services",
      src: S3,
    },
    {
      title: "Monitoring, reporting, and visualization services.",
      src: S4,
    },
    {
      title: " Services for Cloud Migration",
      src: S1,
    },
    {
      title: "Services for managing IT infrastructure.",
      src: S6,
    },
    // {
    //   title: "Artifical Intelligence and Knowledge Engineering",
    //   src: S7,
    // },
    // {
    //   title: "Management of Recovery from Emergency Services",
    //   src: S8,
    // },
  ];

  return (
    <div className={"container"}>
      <PrimaryHeading
        primaryText="Our Most Prominent Services"
        tagline="Deltalake Systems offers comprehensive end-to-end services for data and tool learning analytics."
      />
      <section>
        <div className="row">
          {services.map((item: any) => {
            return (
              <div className={"col-6 col-sm-3 col-md-2"}>
                <GridBox>
                  <img src={item.src} />
                  <p>{item.title}</p>
                </GridBox>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default FourGrid;

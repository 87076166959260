import styled, { css } from "styled-components";
import {
  fontSize_BASE,
  fontSize_SM,
  fontSize_XS,
  fontSize_XXXL,
} from "../customFonts";

export const Banner = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  h2 {
    padding: 15px 0;
    font-weight: 600;

    @media (max-width: 480px) {
      padding-bottom: 20px;
    }
  }

  h2,
  p {
    text-align: center;
  }

  section {
    width: 50%;

    img {
      padding: 50px 0;
      width: 100%;
      height: 300px;
    }
  }

  .banner-img {
    height: 450px;
  }

  @media (max-width: 480px) {
    p {
      padding: 0 10px;
    }

    section {
      width: 100%;
    }

    .banner-img {
      height: 300px;
      object-fit: contain;
    }
  }
`;

export const CountableWrapper = styled.div`
  h5 {
    padding-top: 30px;
    ${fontSize_XXXL};
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    line-height: 53px;
    text-align: center;
    color: #000;
  }
`;

export const NumberColumn = styled.div`
  h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    ${fontSize_XXXL};
    text-align: center;
    color: ${(props: any) => props.theme.primary.sectionHeading};
    padding: 30px 0;
    font-weight: 900;
  }

  p {
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #000;
    ${fontSize_SM};
  }
`;

export const Form = styled.div`
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;

  .row {
    padding-bottom: 20px;
  }

  input,
  textarea {
    background: #eeeeee;
  }
`;

import React from "react";
import { TwoGridBox } from "./styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import I6 from "../../../assets/images/6.svg";

export default function TwoGrid() {
  const services = [
    {
      title: "IT Strategy Consulting Services",
      para: "At Deltalake Systems, we collaborate closely with our clients to comprehend their business aims and targets. This helps us create an IT strategy that is in line with their vision.",
      src: I6,
    },
    {
      title: "IT Infrastructure Consulting",
      para: "With our team of experts, we can assist you in designing, implementing, and managing your IT infrastructure, guaranteeing its security, scalability, and reliability.",
      src: I6,
    },
    {
      title: "Cloud Consulting",
      para: "Our team can assist you in harnessing the capabilities of the cloud to enhance productivity, minimize expenses, and foster teamwork.",
      src: I6,
    },
    {
      title: "Cybersecurity Consulting",
      para: "Our team of cybersecurity specialists can assist you in safeguarding your business against cyber threats through the implementation of strong security measures and continuous monitoring and support.",
      src: I6,
    },
    {
      title: "Data Engineering Consulting",
      para: "Our team can assist you in extracting valuable insights from your data, enabling you to make informed decisions that will enhance your business outcomes.",
      src: I6,
    },
  ];

  return (
    <div className={"container"}>
      <PrimaryHeading
        primaryText="Explore our industry knowledge"
        tagline="At Deltalake Systems, we offer a wide range of comprehensive solutions that 
                 include data engineering, cloud technologies, DevOps, artificial intelligence, 
                 cybersecurity, quality assurance, coding, Salesforce, and JAVA."
      />
      <section>
        <div className="row">
          {services.map((item: any) => {
            return (
              <div className={"col-12 col-sm-6"}>
                <TwoGridBox>
                  <div className="d-flex align-items-center justify-content-start w-100">
                    {/* <img src={item.src} className="pe-3" /> */}
                    <h5>{item.title}</h5>
                  </div>

                  <p>{item.para}</p>
                </TwoGridBox>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}

import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";

export default function BigData() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="Hadoop & Big Data " />
                  <p className="margin-0 pt-15">
                    Big Data has a tremendous impact on organizations worldwide,
                    spanning diverse areas such as healthcare, insurance,
                    transportation, logistics, and customer service.
                  </p>
                  <p className="margin-0 pt-15">
                    As a Big Data Engineer, you can pursue a fascinating and
                    rapidly-evolving career that is projected to grow
                    significantly beyond 2025.
                  </p>
                  <p className="margin-0 mt-3">
                    This certification course offers an introduction to the
                    field of big data engineering, complete with expert seminars
                    and Ask Me Anything sessions.
                  </p>
                  <p className="margin-0 mt-3">
                    The course includes interactive live sessions, practical
                    laboratories, and industry projects, enabling you to develop
                    essential job skills such as proficiency in Big Data and
                    Hadoop frameworks, how to leverage the power of AWS
                    services, and how to use database management tools like
                    MongoDB to store data.
                  </p>
                  <p className="margin-0 mt-3">
                    Through hands-on experience with the tools and systems used
                    by big data scientists and engineers, you will gain a deeper
                    understanding of the insights that big data can provide.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="Big data key featrues" />
                  <p className="margin-0 pt-15">
                    By taking this course, you will gain knowledge on executing
                    predictive modelling and utilizing graph analytics to model
                    problems, by practicing with the provided code. This program
                    will equip you with the skills to ask the right questions
                    about data, effectively communicate with data scientists,
                    and conduct fundamental dataset exploration.
                  </p>

                  <p className="margin-0 pt-15">
                    This program will cover fundamental techniques like data
                    mining and stream processing, as well as teach you how to
                    use tools like MapReduce and the Hadoop cluster to develop
                    and implement PageRank algorithms. Additionally, you'll
                    explore how big data has transformed web search and gain
                    insights into how online advertising functions.
                  </p>
                  <p className="margin-0 pt-15">
                    By the end of this course, you will have a comprehensive
                    understanding of the various applications of big data
                    methodologies in both industry and research.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Learning outcomes" />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          MapReduce knowledge and <br /> application
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        The MapReduce task consists of two main phases: the Map
                        phase and the Reduce phase. During the Map phase, input
                        data is mapped into key-value pairs.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Understanding the frequency of events <br /> occurring
                          in large datasets.
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        The frequency of a given data value refers to the count
                        of how many times that value appears in the dataset.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Create algorithms to process data streams and
                          <br /> calculate the frequency of elements that occur
                          frequently.
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        When it comes to processing, there are several options
                        to choose from, including Samza, Storm, and Spark
                        Streaming, which are the most commonly used stream
                        processing frameworks.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

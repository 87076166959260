import React from "react";
import { Section } from "../styles";
import { Banner } from "../Home/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import Img11 from "../../assets/images/11.svg";

export default function EmpowerDevelopment() {
  return (
    <div>
      <Banner className="py-5">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="w-50">
            <h2>Cloud Infrastructure for your Projects</h2>
            <p>
              Cloud infrastructure comprises the essential elements needed to
              support cloud computing, incorporating hardware components,
              abstracted resources, storage solutions, and network resources.
            </p>
          </div>
        </div>
      </Banner>

      <Section className="">
        <div className="container ">
          <div className="row pt-5">
            <div className="col-12 col-sm-7">
              <PrimaryHeading primaryText="How do you create a cloud infrastructure " />
              <p>
                Cloud computing infrastructure refers to the combination of
                hardware and software components required to facilitate cloud
                computing. This includes computing power, storage, networking
                capabilities, and an interface that enables users to access
                their virtual resources. Cloud infrastructure can be provided by
                a cloud service provider, or it can be developed and maintained
                in-house.
              </p>
              <p>
                As a leading web development company, we rely on a set of
                industry-standard web tools and technologies to deliver
                exceptional solutions to our clients. Our solutions are deeply
                integrated within your enterprise environment, making them
                self-contained and easily accessible. We offer scalable and
                customizable options to meet your specific needs, and our
                pricing is flexible to accommodate your budget. Moreover, our
                solutions are always kept private unless you specify otherwise.
              </p>
              <p>
                We understand that every business has unique requirements, and
                our goal is to deliver solutions that are deeply ingrained in
                your surroundings. Our team of experts works closely with you to
                develop solutions that cater to your specific needs. With our
                expertise in cloud computing infrastructure, we ensure that your
                business runs efficiently, and your resources are utilized
                optimally.
              </p>
            </div>
            <div className="col-12 col-sm-5">
              <img src={Img11} alt="" />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

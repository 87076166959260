import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function DataScience() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Data Science?" />

              <p className="sub-text">
                The demand for data scientists who can analyse data and
                communicate insights to inform data-driven decisions is
                currently at an all-time high, making data science one of the
                most sought-after professions of the decade.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Adoption of DATA SCIENCE in Finance!" />

              <p className="mt-20">
                Large firms in India are increasingly adopting Analytics and
                Data Science, with an overall adoption rate of
                <b className="text-danger" style={{ fontSize: "40px" }}>
                  74.5%
                </b>{" "}
                which is higher than the 70% rate in 2019.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 md-mb-50">
                <div className="services-item">
                  <div className="services-content">
                    <p className="services-title">
                      Professional Certificates in Data Science can help you
                      start a new career or transition to a new one at your own
                      pace. You can enroll anytime and study whenever it is
                      convenient for you. If needed, you can suspend your
                      studies or cancel your membership at any time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-50">
                <div className="services-item">
                  <div className="services-content">
                    <p>
                      The Professional Certificate program focuses on applied
                      learning, with hands-on labs on the Cloud in all courses
                      except the first. These labs will equip you with practical
                      skills that can be applied in real-world situations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading
                primaryText="   How will Data Science change the world?
                        "
              />

              <div className="mt-30 mb-30">
                <img
                  className="hovers-logos rs-grid-img w-50"
                  src="assets/images/pages/data-science/4.png"
                  alt=""
                />
              </div>
              <div className="mt-30 mb-30">
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/_fjUaDj77NI"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

import React from "react";
import { BgHeading, Section } from "../styles";
import BG from "../../assets/images/8.svg";

const Contact = () => {
  return (
    <BgHeading>
      <div className="d-none d-sm-block">
        <img src={BG} alt="" />
      </div>
      <h5 className="d-xs-none">Any Queries - Get in touch with us</h5>
      <div className="container">
        <Section className="d-flex justify-content-center pt-4 pt-sm-5">
          <div className="d-flex justify-content-center">
            <div className="row margin-0">
              <div className="col-lg-12 padding-0">
                <div className="contact-address">
                  <div className="mb-5">
                    <h2 className="title">Get in touch</h2>
                    <p className="margin-0">
                      We appreciate your interest in our IT consulting company
                      for your business requirements. If you have any queries,
                      feedback or requests, please don't hesitate to reach us
                      through the contact details given below. We would be
                      delighted to connect with you and explore how we can
                      assist your business to achieve success.
                    </p>
                    <p>
                      We comprehend that each business has its distinct needs
                      and difficulties. Therefore, we provide personalized
                      solutions to fulfill your specific requirements. Our team
                      of specialists can assist you in areas such as data
                      management, software development, and technology strategy.
                      We are always here to help you.
                    </p>
                    <p>
                      Our topmost priority is to deliver exceptional services
                      that empower businesses to remain competitive in the
                      market. Our team of committed professionals possesses
                      extensive experience and in-depth knowledge of the latest
                      technological developments, enabling us to offer
                      innovative solutions that cater to your business
                      requirements.
                    </p>
                    <p>
                      No matter the size of your business, we are available to
                      offer our assistance. Get in touch with us today to
                      discover more about our services and how we can support
                      you in accomplishing your business objectives.
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-map-marker me-3" />
                    <div className="address-text">Texas 75023 USA. </div>
                  </div>
                  {/* <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-phone me-3" />
                    <div className="address-text">
                      <a href="tel:(+088)589-8745">(+088) 589-8745</a>
                    </div>
                  </div> */}
                  <div className="d-flex align-items-center mb-5">
                    <i className="fa fa-envelope me-3" />
                    <div className="address-text">
                      <a href="mailto:info@deltalake.com">info@deltalake.com</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 padding-0">
                <div className="rs-contact main-home office-modify1 pt-50 pb-50 md-pt-50 md-pb-50">
                  <div className="container">
                    <div className="row margin-0">
                      <div className="col-lg-6 padding-0 office-buliding" />
                      <div className="col-lg-6 padding-0">
                        <div className="contact-section contact-style2">
                          <div className="sec-title mb-60">
                            <h2 className="title">Contact us</h2>
                          </div>
                          <div className="contact-wrap">
                            <div id="form-messages" />
                            <form
                              id="contact-form"
                              method="post"
                              action="mailer.php"
                            >
                              <fieldset>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="name"
                                      name="name"
                                      placeholder="Name"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="email"
                                      name="email"
                                      placeholder="E-Mail"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="phone"
                                      name="phone"
                                      placeholder="Phone Number"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="Website"
                                      name="subject"
                                      placeholder="Your Website"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-12 mb-35">
                                    <textarea
                                      className="form-control"
                                      id="message"
                                      name="message"
                                      placeholder="Your message Here"
                                      required
                                      defaultValue={""}
                                    />
                                  </div>
                                </div>
                                <div className="btn-part">
                                  <div className="form-group mb-0">
                                    <input
                                      className="readon submit"
                                      type="submit"
                                      defaultValue="Submit Now"
                                    />
                                  </div>
                                </div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </Section>
      </div>
    </BgHeading>
  );
};

export default Contact;

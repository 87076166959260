export const theme = {
  primary: {
    THEME: "#0f252f",
    SECONDARY_THEME: "#E8292E",
    sectionHeading: "#a93030",
  },
  danger: {
    DANGER: "#E8292E",
  },
};

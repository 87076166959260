import React from "react";
import { SectionHeading } from "../styles";

export default function Primaryheading(props: any) {
  return (
    <SectionHeading>
      <div className="d-flex align-items-center pb-1 pb-sm-4">
        <b className="text-center">{props.primaryText}</b>
      </div>
      {props.tagline && <p className="tagline">{props.tagline}</p>}
    </SectionHeading>
  );
}

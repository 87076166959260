import React from "react";

import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function Snowflake() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img15 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="About Snowflake & Administration" />
              <p className="sub-text">
                Snowflake is a data warehouse platform supported by the cloud,
                which simplifies the workload of Database Administrators by
                providing appropriate resources and automating tasks, resulting
                in enhanced data adoption techniques. The management of data
                platforms, particularly on the cloud, can be challenging;
                however, Snowflake's administration handles this task on your
                behalf.
              </p>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Snowflake assistance at Deltalake SystemsInc." />
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 mb-50">
                <div className="services-item">
                  {/* <div className="services-icon">
                          <img src="assets/images/services/style3/2.png" alt="Services">
                      </div> */}
                  <div className="services-content">
                    <p className="services-txt">
                      Having collaborated with various clients, our team at
                      Deltalake Systems Inc. comprehends that each company has
                      unique needs and grapples with distinct challenges.
                      Leveraging our skilled Snowflake administrators, we
                      provide a range of services, including:{" "}
                    </p>
                    <ul className="icon-item mt-20">
                      <li>
                        <p className="list-text">
                          Managing user profiles and access levels to ensure
                          secure and efficient use of the Snowflake platform.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Implementing cost-effective strategies for managing
                          data storage, processing, and user access on the
                          Snowflake platform.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Leveraging automated tools for Snowflake platform
                          development and optimization.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Selecting and utilizing appropriate tools that align
                          with the Snowflake platform's capabilities and
                          requirements.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Creating a well-organized and efficient data
                          architecture and structure for the Snowflake platform.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Regularly monitoring and managing Snowflake tools to
                          ensure optimal performance and functionality.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Troubleshooting platform errors and issues to maintain
                          the stability and reliability of the Snowflake
                          platform.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Ensuring seamless connectivity between data sources
                          and the Snowflake platform for efficient data
                          processing and management.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Optimizing costs by paying only for the specific
                          services you need on the Snowflake platform
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

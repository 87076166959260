import React from "react";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { Section } from "../styles";

export default function AIStrategy() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img10 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="What is Artificial Intelligence (AI)?" />
              <p className="sub-text">
                AI, as a scientific field, focuses on enabling machines to solve
                intricate problems using approaches similar to those used by
                humans.
              </p>
              <p className="sub-text">
                This often involves utilizing features of human intelligence and
                adapting them into algorithms that can be applied effectively in
                computer systems.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div
          id="rs-about"
          className="rs-about main-home pt-50 pb-100 md-pt-70 md-pb-70"
        >
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-5 md-mb-50">
                <div className="images-part">
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/Cx5aNwnZYDc"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="sec-title3">
                  <PrimaryHeading primaryText="Importance of Artificial intelligence (AI):" />

                  <p className="">
                    AI technology allows software to perform tasks related to
                    human understanding, planning, reasoning, communication, and
                    perception with greater effectiveness, efficiency, and
                    affordability.
                  </p>
                  <ul className="icon-item">
                    <li>
                      <p className="list-text">A more or less flexible</p>
                    </li>
                    <li>
                      <p className="list-text">
                        The degree of artificial intelligence in behavior can be
                        influenced by the established requirements, which in
                        turn can determine the most efficient approach to take.
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        Artificial Intelligence (AI) is a vast area of computer
                        science that focuses on creating intelligent machines
                        that can perform tasks that typically necessitate human
                        intelligence.{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="You need to understand four significant AI concepts, including face recognition." />
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Categorization</a>
                    </h2>
                    <p className="services-txt">
                      {" "}
                      The initial stage in developing an AI solution involves
                      the formulation of what I refer to as "design intent
                      metrics." These metrics serve the purpose of classifying
                      the problem at hand.
                    </p>
                    <p className="services-txt">
                      {" "}
                      No matter what kind of system users aim to create, whether
                      it's for playing Jeopardy, aiding doctors in diagnosing
                      cancer, or assisting IT administrators in diagnosing
                      wireless issues, they must establish metrics that can
                      enable them to break down the problem into smaller
                      components. In the case of wireless networking, crucial
                      metrics include user connection time, throughput,
                      coverage, and roaming. Similarly, in cancer diagnosis,
                      important metrics to consider are white cell count, ethnic
                      background, and X-ray scans.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Classification</a>
                    </h2>
                    <p className="services-txt">
                      After categorizing the problem into distinct areas, the
                      subsequent step for users is to create classifiers for
                      each category, which can guide them towards a meaningful
                      resolution. To illustrate, when teaching an AI system to
                      play Jeopardy, users must initially classify a question as
                      either literal or a wordplay, and then classify it by its
                      subject matter - time, person, thing, or place. Similarly,
                      in wireless networking, once users identify the problem's
                      category (such as a pre- or post-connection issue), they
                      must begin to classify the root cause of the problem, such
                      as association, authentication, dynamic host configuration
                      protocol (DHCP), or other factors relating to wireless,
                      wired, or device-related issues.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Machine learning</a>
                    </h2>
                    <p className="services-txt">
                      Having separated the problem into metadata-specific
                      domains, users can proceed to input this data into the
                      remarkable realm of machine learning. Numerous algorithms
                      and techniques exist for machine learning, with supervised
                      machine learning employing neural networks (or deep
                      learning) currently being one of the most prevalent
                      approaches. The idea of neural networks dates back to
                      1949, and I created my initial neural network in the
                      1980s. <br />
                      Thanks to recent advancements in computing and storage
                      capabilities, neural networks can now be trained to tackle
                      a diverse range of real-world issues, spanning from image
                      recognition and natural language processing to forecasting
                      network performance. Other applications of neural networks
                      include detecting anomaly features, identifying anomalies
                      in time series, and correlating events for root cause
                      analysis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Collaborative filtering</a>
                    </h2>
                    <p className="services-txt">
                      The majority of individuals encounter collaborative
                      filtering when they select a film on Netflix or purchase a
                      product from Amazon and subsequently receive suggestions
                      for other movies or items that may pique their interest.
                      In addition to recommender systems, collaborative
                      filtering is also employed to sift through extensive
                      datasets and provide a visual representation of an AI
                      solution.
                    </p>
                    <p className="services-txt">
                      At this stage, all the data collection and analysis are
                      transformed into valuable insights or actionable outcomes.
                      Whether it's utilized on a game show, in the medical
                      field, or by a network administrator, collaborative
                      filtering serves as the pathway to generating responses
                      with a high level of confidence. It operates like a
                      virtual assistant that aids in resolving intricate
                      problems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

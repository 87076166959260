import React from "react";
import { Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

export default function Dataiku() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img9 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <Primaryheading primaryText="Dataiku - A Tool for Expertise" />
              <span className="sub-text">
                Dataiku is a modern sensation that is acknowledged as a platform
                which not only utilizes Artificial Intelligence (AI), but also
                organizes data to achieve remarkable business outcomes, while
                bringing people together. It offers an integrated virtual
                workspace where users have the freedom to work as they wish
              </span>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div>
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-12">
                <div className="sec-title3">
                  <Primaryheading
                    primaryText="What sets apart deltalake systems from others?
                            "
                  />

                  <p className="margin-0 pb-40">
                    At deltalake Inc., we offer unparalleled guidance and
                    expertise in Dataiku to our clients. We specialize in
                    simplifying Dataiku implementation and execution, ensuring a
                    seamless experience for our clients. .{" "}
                  </p>
                  <ul className="icon-item">
                    <li>
                      <p className="list-text">
                        At deltalake Inc, we offer our clients top-notch
                        guidance on Dataiku. We strive to simplify the
                        implementation and execution of Dataiku for our clients.
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        Our team of professional strategists creates informative
                        guides to train your team on various Dataiku models.{" "}
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        Our support services are available round-the-clock to
                        ensure that your Dataiku platform operates seamlessly.
                        We employ highly-effective administration techniques to
                        maintain optimal performance.{" "}
                      </p>
                    </li>
                  </ul>
                  <p className="margin-0 pb-40">
                    For more information and assistance, you can contact
                    deltalake Inc .{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-100 pb-10">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText=" Automate analytics for a self-service business." />

              <p className="services-txt mt-30 mb-30">
                We utilize a range of AI tools to provide the performance and
                service that our clients require. Our effective AI strategies
                ensure the smooth operation of your machine learning roadmap.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-20 pb-10">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText="Web Apps and More" />
              <p className="services-txt mt-30 mb-30">
                Dataiku accelerates business progress by seamlessly automating
                scheduling processes. It enables businesses to automate
                recurring analytical tasks without depending on IT teams or data
                scientists. This allows individuals to focus on more impactful
                and productive data assignments.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

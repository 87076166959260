import React from "react";
import HeaderBanner from "./HeaderBanner";
import FourGrid from "../UIComponents/FourGrid";
import { Section } from "../styles";
import { CountableWrapper, NumberColumn, Form } from "./styles";
import TwoGrid from "../UIComponents/TwoGrid";
import Button from "../DesignSystem/Button";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function Home() {
  return (
    <>
      <HeaderBanner />

      <Section>
        <FourGrid />
      </Section>

      <Section>
        <TwoGrid />
      </Section>

      <Section className="pb-5 bg-gray">
        <div className="container">
          <CountableWrapper>
            <PrimaryHeading primaryText="We are a leading data service provider that specializes in turning evolutionary computation and analytics projects into reality." />
          </CountableWrapper>
          <div className="row pb-4">
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>1000</h5>
                <p>
                  We have developed customized ingest funnels <br /> for our
                  clients using our open-source
                  <br /> data importation platform.
                </p>
              </NumberColumn>
            </div>
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>500+</h5>
                <p>
                  For more than a decade, our team of experts
                  <br /> in the data engineering department <br /> has been
                  executing projects.
                </p>
              </NumberColumn>
            </div>
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>90%</h5>
                <p>
                  The combined retention rate for DataOps,
                  <br /> management services,
                  <br />
                  and MLOps.
                </p>
              </NumberColumn>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section>
        <WorkWith />
      </Section> */}

      <Section className="pb-5 bg-gray">
        <div className="container">
          <CountableWrapper>
            <PrimaryHeading
              primaryText="Collaborated With Us to Take the First Step on the Journey
              Toward Success."
            />
          </CountableWrapper>
          <div className="row pb-4 pt-5 align-items-center">
            <div className="col-12 col-sm-7">
              <>
                <p className="" style={{ lineHeight: 2 }}>
                  Our expertise in system updates, hosting services, security,
                  and data platform maintenance makes us the perfect solution
                  for both enterprises and startups. By leveraging our advanced
                  technology and specialized team, you can explore options that
                  most suppliers won't provide. We are dedicated to providing
                  the best possible solutions for our clients, and we take pride
                  in our ability to understand their needs and provide tailored
                  services.
                </p>
              </>
            </div>
            <div className="col-12 col-sm-5">
              <Form>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      placeholder="Company Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Contact Number"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <textarea
                      placeholder="Write a short message here."
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12 d-flex justify-content-center">
                    <Button variant="secondary">Let's connect</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

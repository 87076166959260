import React from "react";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function MicrosoftAzure() {
  return (
    <div>
      <Section className="bg-gray">
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img1 machine-learning-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Microsoft's Azure, the cloud platform for innovation and limitless creativity." />
              <span className="sub-text">
                With over 200 products and numerous services available online,
                Microsoft Azure is a public cloud platform.
              </span>
              <p className="sub-text">
                Having expanded since 2008, Microsoft Azure has now become the
                second-largest among the top three public cloud platforms. It is
                closely trailing behind the market leader, Amazon Web Services
                (AWS), and is ahead of Google Cloud Platform (GCP).
              </p>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}

      {/* Services Section Start */}
      <Section>
        <div className="">
          <div className="container custom">
            <div className="services-box-area bg20">
              <div className="row margin-0">
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">
                          <a href="">Cloud Platform You Can Rely On</a>
                        </h5>
                        <p className="margin-0">
                          Obtain complete security right from the foundation,
                          supported by a team of professionals, and proactive
                          compliance that is trusted by both startups and
                          governments.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">
                          <a href="">A hybrid cloud approach</a>
                        </h5>
                        <p className="margin-0">
                          We will meet you where you are, whether it be
                          on-premises, across multiple clouds, or at the edge.
                          Manage and integrate your environments with services
                          that are specifically designed for hybrid cloud.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">
                          <a href="">Innovation with Microsoft Azure</a>
                        </h5>
                        <p className="margin-0">
                          One of the key benefits of using Microsoft Azure is
                          its ability to drive innovation and creativity in
                          businesses.With Azure, developers can build and deploy
                          applications faster and more efficiently, using a wide
                          range of tools and technologies.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item no-border">
                      <div className="services-content">
                        <h5 className="title">
                          <a href="">The Future ready</a>
                        </h5>
                        <p className="margin-0">
                          Microsoft's continuous innovation supports your
                          current development needs as well as your future
                          product visions.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}

      {/* Services Section Start */}
      <Section>
        <div
          id="rs-services"
          className="rs-services main-home services-style1 home-4-style bg7 pt-95 pb-100 md-pt-70 md-pb-65"
        >
          <div className="container">
            <div className="sec-title3 text-center mb-65 md-mb-45">
              <PrimaryHeading
                primaryText=" What is Microsoft Azure Cloud? &amp; Benefits of
                    Microsoft Azure Cloud"
              />
              <div className="heading-border-line" />
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Recovery Process</a>
                      </h5>
                      <p className="services-txt">
                        Microsoft offers built-in disaster recovery tools that
                        help keep critical applications running during planned
                        and unplanned outages. Users can replicate, failover,
                        and recover processes as needed, while also minimizing
                        downtime by sequencing multi-tier applications running
                        on multiple virtual machines.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Compute Services</a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        This group of services enables companies to rapidly
                        provision both Linux and Windows virtual machines,
                        achieve high availability through autoscaling, and
                        enhance app development with a serverless architecture.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Microsoft Azure Storage</a>
                      </h5>
                      <p className="services-txt">
                        Microsoft's virtual datacenters offer businesses
                        flexible connectivity and adaptable infrastructure,
                        eliminating the requirement for on-premises hardware.
                        Data written to Azure is encrypted and can be accessed
                        through HTTP or HTTPS.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 md-mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure's data analytics offerings</a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Azure's advanced analytics services enable businesses to
                        accelerate their decision-making processes by providing
                        unparalleled time-to-insight. With a fully managed cloud
                        Hadoop or Spark environment, users can perform real-time
                        data stream processing.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Networking services offered by Azure</a>
                      </h5>
                      <p className="services-txt">
                        This set of features enables organizations to build
                        hybrid computing frameworks that leverage both private
                        and public cloud infrastructure, allowing users to
                        seamlessly deploy their customized applications to
                        thousands of virtual machines.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 sm-mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">
                        <a href="">Azure Backup</a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        These services streamline the process of data recovery
                        by enabling users to back up virtual machines, SQL
                        workloads, and on-premises VMware with just one click.
                        With Azure, businesses can restore their data swiftly
                        using VSS snapshot or fsfreeze without worrying about
                        application consistency.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}
    </div>
  );
}

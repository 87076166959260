import React from "react";

export default function ManagedMLOps() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      <div className="rs-breadcrumbs img17 aws-banner">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">Cloud DataOps</h1>
            <span className="sub-text">
              DataOps is a methodology that applies Agile Development, DevOps
              and lean manufacturing to analytics to maximize business agility.
            </span>
          </div>
        </div>
      </div>
      {/* Breadcrumbs End */}

      <div
        id="rs-about"
        className="rs-about main-home pt-50 pb-100 md-pt-70 md-pb-70"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  The Stages of the Agile Software Development Life Cycle?
                </h2>
                {/* <p className="margin-0 pb-40">AI enables human capabilities of understanding, planning, reasoning, communication and perception to be undertaken by software increasingly effectively, efficiently and at low cost.</p> */}
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Scope out and prioritize projects
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Diagram requirements for the initial sprint
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Construction/iteration</span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Release the iteration into production
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Production and ongoing support for the software release
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">Retirement</span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Traditional solution architects for systems that involve
                      SQL databases, web servers, SAP installations and other
                      “standard” 12 systems..
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 md-mb-30 mt-30">
              <div className="images-part">
                <img
                  src="assets/images/pages/cloud-dataops/1.png"
                  alt="Images"
                  className="w-100 "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services style3 pt-50 pb-100 md-pt-30 md-pb-70">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              Agile software development sprint planning
            </h2>

            <p className="services-txt mt-30">
              DataOps is a process-driven, automated approach to data delivery
              and analytics. It uses the agile approach between data owners and
              technical teams to improve quality while reducing cycle times. It
              borrows methods from DevOps to bring similar improvements, and
              isn’t tied to any one tool or technology – it’s more an
              amalgamation of culture, approach and methodology.
            </p>
            <p className="services-txt mt-30">
              Within the agile SDLC, work is divided into sprints, with the goal
              of producing a working product at the end of each sprint. A sprint
              typically lasts two weeks, or 10 business days. The workflow of a
              sprint should follow this basic outline:
            </p>
            <p className="services-txt mt-30">
              <b>Plan</b>The sprint begins with a sprint planning meeting, where
              team members come together to lay out components for the upcoming
              round of work. The product manager prioritizes work from a backlog
              of tasks to assign the team.
            </p>
            <p className="services-txt mt-30">
              <b>Develop</b> Design and develop the product in accordance with
              the approved guidelines.
            </p>
            <p className="services-txt mt-30">
              <b>Test/QA</b> Complete thorough testing and documentation of
              results before delivery.
            </p>
            <p className="services-txt mt-30">
              <b>Deliver</b> Present the working product or software to
              stakeholders and customers.
            </p>
            <p className="services-txt mt-30">
              <b>Assess</b> Solicit feedback from the customer and stakeholders
              and gather information to incorporate into the next sprint.
            </p>
            <div className="mt-30 mb-10">
              <iframe
                width="100%"
                height="600"
                src="https://www.youtube.com/embed/WjwEh15M5Rw"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50 custom-bg">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">DataOps benefits?</h2>
            <p className="service-txt">
              By improving the quality and reducing the time of data analytics,
              as you can imagine, things get done much more quickly. This means
              businesses can move faster and more accurately to unlock value in
              their data.
            </p>
            <img
              src="assets/images/pages/cloud-dataops/3.png"
              alt="Services"
              className="mt-30"
            />
          </div>
        </div>
      </div>
      {/* Services Section End */}
    </div>
  );
}

import React, { useEffect, useState, FunctionComponent } from "react";
import { HeaderWrapper, MenuList } from "./styles";
import Router from "../../routes";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import logoImg from "../../../assets/images/logo.svg";

const Button = loadable(() => import("../../DesignSystem/Button"));

const Header: FunctionComponent = () => {
  const [isNavigation, setIsNavigation] = useState(false);

  const onNavigation = () => {
    setIsNavigation(!isNavigation);
  };

  const onClose = () => {
    setIsNavigation(false);
  };

  return (
    <>
      <HeaderWrapper>
        <nav className="navbar navbar-expand-lg w-100 container">
          <div className="container-fluid d-flex justify-content-between">
            <Link to="/Home">
              <img src={logoImg} alt="logo" className={"logo"} />
            </Link>

            <button
              className="navbar-toggler d-sm-none"
              type="button"
              onClick={onNavigation}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="main-menu d-xs-none">
              <MenuList className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Company
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/EmpowerDevelopment">
                            Empower development
                          </Link>
                        </li>
                        <li>
                          <Link to="/AboutUs">About Us</Link>
                        </li>
                        <li>
                          <Link to="/Clients">Clients</Link>
                        </li>
                        <li>
                          <Link to="/Contact">Questions?</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cloud Technologies
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/AWS">Amazon Web Services</Link>
                        </li>
                        <li>
                          <Link to="/MicrosoftAzure">Microsoft Azure</Link>
                        </li>
                        <li>
                          <Link to="/Snowflake">Snowflake</Link>
                        </li>
                        <li>
                          <Link to="/Dataiku">Dataiku</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Development
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/BigData">Big Data & Hadoop</Link>
                        </li>
                        <li>
                          <Link to="/DataScience">Data Science</Link>
                        </li>
                        <li>
                          <Link to="/Machinelearning">Machine learning</Link>
                        </li>
                        <li>
                          <Link to="/AIStrategy">Artificial Strategy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Knowledge Transfer
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/CaseStudies">Case Studies</Link>
                        </li>
                        <li>
                          <Link to="/Videos">Videos</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <Link to="/Careers" className="nav-item">
                    Careers
                  </Link>
                </li>

                <li>
                  <Link to="/Contact">
                    <Button
                      title="Contact"
                      // className="ms-3"
                      variant="secondary"
                      isHideIcon={true}
                    >
                      Reach us
                    </Button>
                  </Link>
                </li>
              </MenuList>
            </div>

            {isNavigation && (
              <div className="sidebar">
                <MenuList className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarScrollingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Company
                      <i className="fa fa-chevron-down" />
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <div className="d-flex justify-content-center sub-menu-wrapper">
                        <ul className="left">
                          <li>
                            <Link to="/EmpowerDevelopment" onClick={onClose}>
                              Empower development
                            </Link>
                          </li>
                          <li>
                            <Link to="/AboutUs" onClick={onClose}>
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link to="/Clients" onClick={onClose}>
                              Clients
                            </Link>
                          </li>
                          <li>
                            <Link to="/Contact" onClick={onClose}>
                              Questions?
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarScrollingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Cloud Technologies
                      <i className="fa fa-chevron-down" />
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <div className="d-flex justify-content-center sub-menu-wrapper">
                        <ul className="left">
                          <li>
                            <Link to="/AWS" onClick={onClose}>
                              Amazon Web Services
                            </Link>
                          </li>
                          <li>
                            <Link to="/MicrosoftAzure" onClick={onClose}>
                              Microsoft Azure
                            </Link>
                          </li>
                          <li>
                            <Link to="/Snowflake" onClick={onClose}>
                              Snowflake
                            </Link>
                          </li>
                          <li>
                            <Link to="/Dataiku" onClick={onClose}>
                              Dataiku
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarScrollingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Development
                      <i className="fa fa-chevron-down" />
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <div className="d-flex justify-content-center sub-menu-wrapper">
                        <ul className="left">
                          <li>
                            <Link to="/BigData" onClick={onClose}>
                              Big Data & Hadoop
                            </Link>
                          </li>
                          <li>
                            <Link to="/DataScience" onClick={onClose}>
                              Data Science
                            </Link>
                          </li>
                          <li>
                            <Link to="/Snowflake" onClick={onClose}>
                              Machine learning
                            </Link>
                          </li>
                          <li>
                            <Link to="/AIStrategy " onClick={onClose}>
                              Artificial Strategy
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarScrollingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Knowledge Transfer
                      <i className="fa fa-chevron-down" />
                    </a>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <div className="d-flex justify-content-center sub-menu-wrapper">
                        <ul className="left">
                          <li>
                            <Link to="/CaseStudies" onClick={onClose}>
                              Case Studies
                            </Link>
                          </li>
                          <li>
                            <Link to="/Videos" onClick={onClose}>
                              Videos
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li>
                    <Link to="/Careers" className="nav-item" onClick={onClose}>
                      Careers
                    </Link>
                  </li>
                </MenuList>
                <Link to="/Contact" onClick={onClose}>
                  <Button
                    title="Contact"
                    className="ms-3"
                    variant="secondary"
                    isHideIcon={true}
                  >
                    Reach us
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </nav>
      </HeaderWrapper>

      <Router />
    </>
  );
};

export default Header;
